/* @format */
import React from "react";
import PropType from "prop-types";
import clsx from "clsx";

import { useCommonStyles } from "../styles";

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

export default function Block({
    header,
    headerComponent,
    children,
    className,
    headerClassName,
    contentClassName,
    //icon,
    ...other
}) {
    const classes = useCommonStyles();
    return (
        <Paper className={clsx(className, classes.block)} {...other}>
            {React.createElement(
                headerComponent,
                { className: clsx(headerClassName, classes.header) },
                header
            )}
            <Typography
                component="div"
                className={clsx(contentClassName, classes.blockContent)}
            >
                {children}
            </Typography>
        </Paper>
    );
}

Block.propTypes = {
    header: PropType.node.isRequired,
    headerComponent: PropType.elementType,
    children: PropType.node.isRequired,
    icon: PropType.node,
    className: PropType.string,
    headerClassName: PropType.string,
    contentClassName: PropType.string
};
Block.defaultProps = {
    icon: null,
    headerComponent: "h2",
    className: null,
    headerClassName: null,
    contentClassName: null
};
