/* @format */
import React from "react";
import PropType from "prop-types";

import { makeCommonStyles } from "../styles";
import clsx from "clsx";

import Block from "../components/Block";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { Button } from "gatsby-theme-material-ui";

function FeatureBlock({ name, children, link, href, classes }) {
    return (
        <Grid item xs={12} sm={6} md={4} className={classes.frame}>
            <Block
                header={name}
                className={clsx(classes.frameMain, classes.frame)}
                contentClassName={clsx(classes.frame, classes.fullHeight)}
            >
                <Typography component="div" className={classes.frameMain}>
                    {children}
                </Typography>
                <div className={classes.btnWrapper}>
                    <Button variant="outlined" href={href}>
                        {link || name}
                    </Button>
                </div>
            </Block>
        </Grid>
    );
}

FeatureBlock.propTypes = {
    name: PropType.string.isRequired,
    children: PropType.element.isRequired,
    link: PropType.string,
    href: PropType.string.isRequired,
    classes: PropType.object.isRequired
};
FeatureBlock.defaultProps = {
    link: null
};

const useStyles = makeCommonStyles((theme) => ({
    btnWrapper: {
        paddingTop: theme.spacing(2),
        textAlign: "right"
    }
}));

export default function FeatureGrid() {
    const classes = useStyles();
    return (
        <Grid container spacing={3} alignItems="stretch">
            <FeatureBlock
                name="Aufgaben"
                link="Zu den Aufgaben..."
                href="/aufgaben/"
                classes={classes}
            >
                <p>Maßgeschneiderte Aufgaben für Dich</p>
            </FeatureBlock>
            <FeatureBlock
                name="Tagesplan"
                link="Zum Tagesplan..."
                href="/tagesplan/"
                classes={classes}
            >
                <p>Dein Tagesplan mit allen Aufgaben des heutigen Tages</p>
            </FeatureBlock>
            <FeatureBlock
                name="Virtuelles Klassenzimmer"
                link="Zum Klassenraum..."
                href="/klassen/meine-gruppen/"
                classes={classes}
            >
                <p>Triff Deine Gruppe im virtuellen Klassenraum</p>
            </FeatureBlock>
            <FeatureBlock
                name="Dokumente und Downloads"
                link="Zu den Dokumenten..."
                href="/dokumente/"
                classes={classes}
            >
                <p>
                    Lade Dokumente herunter und teile eigene Resultate mit der
                    Gruppe oder Deinem Betreuer.
                </p>
            </FeatureBlock>
            <FeatureBlock
                name="Beratung"
                link="Zur Beratung..."
                href="/beratung/"
                classes={classes}
            >
                <p>Suche Hilfe bei Deinem persönlichen Betreuer</p>
            </FeatureBlock>
            <FeatureBlock
                name="Spiel und Spaß"
                link="Zu den Spielen..."
                href="/spiele/"
                classes={classes}
            >
                <p>
                    Entspanne Dich bei interessanten Spielen oder verabrede Dich
                    zum Online-Zocken.
                </p>
            </FeatureBlock>
        </Grid>
    );
}
