/* @format */
import React from "react";
//import PropTypes from "prop-types";
import Background from "./Background";

import { makeCommonStyles } from "../styles";

import UserIndicator from "./UserIndicator";

const useStyles = makeCommonStyles((theme) => ({
    header: {
        height: 400,
        position: "relative"
    },
    intro: {
        position: "absolute",
        bottom: 20,
        left: 0,
        right: 0,
        background: "rgba(0,0,0,0.4)",
        color: "white",
        fontWeight: "bold",
        padding: theme.spacing(2),
        "& h1": {
            fontSize: "xx-large",
            marginBottom: 0
        }
    },
    topRight: {
        position: "absolute",
        right: theme.spacing(3),
        top: theme.spacing(3)
    }
}));

const MainHeader = () => {
    const classes = useStyles();
    return (
        <Background>
            <div className={classes.header}>
                <div className={classes.intro}>
                    <div className={classes.content}>
                        <h1>apeiros edu</h1>
                        <p>Die Lernplattform</p>
                    </div>
                </div>
                <UserIndicator
                    className={classes.topRight}
                    hideLogin={true}
                    color="default"
                />
            </div>
        </Background>
    );
};

export default MainHeader;
