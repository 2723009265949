/* @format */
import React, { useMemo } from "react";
import { navigate } from "gatsby";

import useLoginContext from "@sa/react-login/useLoginContext";

import Layout from "../components/layout";
import MainHeader from "../components/MainHeader";
import Seo from "../components/seo";

import FeatureGrid from "../content/FeatureGrid";

import Button from "@material-ui/core/Button";
import Block from "../components/Block";

import { makeCommonStyles } from "../styles";

const useStyles = makeCommonStyles((theme) => ({
    weakHeader: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary
    }
}));
const IndexPage = () => {
    const classes = useStyles();
    const loginContext = useLoginContext();
    const loggedIn = useMemo(
        () => loginContext && !loginContext.isAnonymous(),
        [loginContext]
    );

    return (
        <Layout>
            <Seo title="Home" />
            <MainHeader />
            <main className={classes.content}>
                <Block header="Herzlich willkommen!">
                    <p>Was es hier bald alles gibt....</p>
                    {loggedIn ? (
                        <p>
                            Schön dass Du hier bist Nutzer{" "}
                            {loginContext.getUserInfo()["login"]}
                        </p>
                    ) : (
                        <p style={{ textAlign: "center" }}>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    navigate("/user/login", {
                                        state: {
                                            redirect: "/"
                                        }
                                    });
                                }}
                            >
                                Zur Anmeldung
                            </Button>
                        </p>
                    )}
                </Block>
                <hr />
                <FeatureGrid />
                <hr />
                <Block
                    header="Disclaimer!"
                    headerClassName={classes.weakHeader}
                    headerComponent="h6"
                >
                    <p>
                        <strong>Hinweis</strong>: Die Plattform befindet sich im
                        Aufbau und ist zur Zeit noch <strong>NICHT</strong>{" "}
                        funktionsfähig. Wir arbeiten daran und erweitern die
                        Funktionalität Stück für Stück.
                    </p>
                    <p>
                        Anregungen sendet einfach an{" "}
                        <a href="mailto:hilfe@edu.apeiros.de">
                            hilfe@edu.apeiros.de
                        </a>
                    </p>
                </Block>
            </main>
        </Layout>
    );
};

export default IndexPage;
