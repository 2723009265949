/* @format */
import React from "react";
import PropType from "prop-types";
import { graphql, useStaticQuery } from "gatsby";

import BackgroundImage from "gatsby-background-image";

/**
 * In this functional component a fullscreen <BackgroundImage /> may be created
 * with art-directed images.
 * @param className   string    className(s) from styled-components.
 * @param children    nodes     Child-components.
 * @return {*}
 * @constructor
 */
export default function Background({ className, children }) {
    const { desktop, medium, small } = useStaticQuery(
        graphql`
            query {
                desktop: file(
                    relativePath: { eq: "shutterstock-450689383.jpg" }
                ) {
                    childImageSharp {
                        fluid(quality: 100, maxWidth: 4160) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                medium: file(
                    relativePath: { eq: "shutterstock-450689383.jpg" }
                ) {
                    childImageSharp {
                        fluid(maxWidth: 1400, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                small: file(
                    relativePath: { eq: "shutterstock-450689383.jpg" }
                ) {
                    childImageSharp {
                        fluid(maxWidth: 490, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `
    );

    // Art-Direction Array
    const backgroundArtDirectionStack = [
        small.childImageSharp.fluid,
        {
            ...medium.childImageSharp.fluid,
            media: "(min-width: 491px)"
        },
        {
            ...desktop.childImageSharp.fluid,
            media: "(min-width: 1401px)"
        }
    ];

    return (
        <BackgroundImage
            Tag="div"
            className={className}
            fluid={backgroundArtDirectionStack}
            backgroundColor="#040e18"
            id="adfullscreenbg"
            role="img"
            aria-label="Background image"
            preserveStackingContext={true}
        >
            {children}
        </BackgroundImage>
    );
}

Background.propTypes = {
    className: PropType.string,
    children: PropType.node
};
Background.defaultProps = {
    className: null,
    children: null
};
